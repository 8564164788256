:root {
  .mat-select-panel {
    @apply tw-rounded tw-shadow tw-p-2;
  }

  .mat-option {
    @apply tw-rounded tw-text-sm tw-font-medium tw-h-10 tw-transition tw-duration-100 tw-ease-out;

    &:hover, &.mat-active {
      @apply tw-bg-primary/10;

      .mat-icon {
        @apply tw-text-primary;
      }
    }

    .mat-icon {
      @apply tw-transition tw-duration-100 tw-ease-out;
      font-size: 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }

    &.mat-selected:not(.mat-option-multiple) {
      @apply tw-bg-primary/20;
    }
  }

  .mat-menu-item-submenu-trigger {
    @apply tw-pr-12;
  }
}
