:root {
  .mat-menu-panel {
    @apply tw-rounded tw-shadow;
  }

  .mat-menu-content {
    @apply tw-p-2;
  }

  .mat-menu-item {
    @apply tw-flex tw-items-center tw-rounded tw-text-sm tw-font-medium tw-h-10 tw-pl-2 tw-pr-6 tw-transition tw-duration-100 tw-ease-out;

    &:hover:not([disabled]) {
      @apply tw-bg-primary/10;

      .mat-icon {
        @apply tw-text-primary;
      }
    }

    .mat-icon {
      @apply tw-transition tw-duration-100 tw-ease-out;
      font-size: 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .mat-menu-item-submenu-trigger {
    @apply tw-pr-12;
  }
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: currentColor;
}
