@import "./var.scss";

.container {
  padding-left: var(--padding-gutter);
  padding-right: var(--padding-gutter);

  @screen lg {
    margin-left: auto;
    margin-right: auto;
    max-width: theme('screens.lg');
    width: 100%;
  }
}

.tw-card {
  @apply tw-rounded tw-shadow tw-bg-foreground;
}

.avatar {
  @apply tw-h-10 tw-w-10 tw-object-cover tw-rounded-full tw-bg-hover;
}

.hidden-input {
  @apply tw-border-2 tw-border-solid tw-border-transparent tw-py-1 tw-px-3 tw-rounded;

  &:focus {
    @apply tw-border-primary tw-outline-none;
  }

  &.empty {
    @apply tw-border-primary;
  }
}

.bg-pattern {
  background: linear-gradient(135deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px),
  linear-gradient(225deg, var(--background-base) 22px, var(--background-hover) 22px, var(--background-hover) 24px, transparent 24px, transparent 67px, var(--background-hover) 67px, var(--background-hover) 69px, transparent 69px) 0 64px;
  background-color: var(--background-base);
  background-size: 64px 128px;
}

// Transitions

.trans-ease-out {
  transition: $swift-ease-out;
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
