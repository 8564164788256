@import "./variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

div {
  height: auto;
}

.sidebar {
  z-index: 1;


  &-collapsed {
    @include make-col-ready();
    width: $sidebar-initial-width !important;
    padding: 0px;

    &-content-viewer {
      @include make-col-ready();
      width: calc(100% - #{$sidebar-initial-width}) !important;
      padding: 0px;
    }
  }

  &-expanded {
    @include make-col(2);
    min-width: $sidebar-minimum-width;
    padding: 0px;

    &-content-viewer {
      @include make-col(10);
      max-width: calc(100% - #{$sidebar-minimum-width});
      padding: 0px;
    }
  }
}

.component {
  &-container {
    @include make-container();
    height: 100%;
    background-color: #f7fcfa;
    padding: 0px;
  }

  &-heading {

    &-container {
      @include make-col(12);
      background-color: rgba(187, 187, 187, 0.6);
      padding: 10px 0px;
    }

    &-text {
      font-size: 16px;
      font-weight: 900;
      padding-left: 6px;
      margin-left: 10px;
      margin-bottom: 0px;
      border-left: 9px $orange-bg-color solid;
      color: #292828;
      padding: 5px;
      display: inline-block;
    }
  }

  &-content-view {
    @include make-row();
    height: calc(100% - 60px);
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;

    .heading {
      &-container {
        @include make-col(12);
        padding: 10px 0px;
      }

      &-value {
        font-size: 15px !important;
        font-weight: bold !important;
        display: inline-block;
      }
    }
  }
}


