:root {
  .mat-button, .mat-stroked-button, .mat-flat-button, .mat-raised-button {
     @apply tw-rounded-button tw-min-w-[6rem];
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply tw-rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply tw-pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply tw-pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply tw-font-medium;
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}