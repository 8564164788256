.vex-layout-ares {
  --footer-background: var(--background-base);
  --sidenav-background: var(--background-base);
  --toolbar-background: var(--background-base);
  --sidenav-color: var(--text-color);
  --sidenav-section-divider-color: transparent;
  --sidenav-item-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-background-active: var(--background-foreground);
  --sidenav-item-color: var(--text-color);
  --sidenav-item-color-hover: var(--text-color);
  --sidenav-item-color-active: var(--text-color);
  --sidenav-item-dropdown-color-active: var(--sidenav-item-color-active);
  --sidenav-item-dropdown-background-active: var(--background-hover);
  --sidenav-item-dropdown-background: var(--background-base);
  --sidenav-item-dropdown-background-hover: var(--background-hover);
  --secondary-toolbar-background: var(--background-base);
  --sidenav-toolbar-background: var(--background-base);
  --secondary-toolbar-height: 64px;

  .sidenav {
    border: none;

    vex-sidenav-item {
      .vex-sidenav-item {
        width: unset;
        @apply tw-rounded-full tw-py-2 tw-mx-4;

        &.vex-sidenav-item--active {
          @apply tw-shadow;
        }
      }

      .item-level-0 > .vex-sidenav-item {
        @apply tw-my-1;
      }

      .item-level-1 .vex-sidenav-item.vex-sidenav-item--active {
        @apply tw-shadow-none tw-font-medium;

        .vex-sidenav-item__label {
          @apply tw-text-black;
        }
      }

      .item-level-1 .vex-sidenav-item:hover .vex-sidenav-item__label {
        @apply tw-text-black;
      }
    }
  }

  .sidenav-collapsed .sidenav vex-sidenav-item .vex-sidenav-item {
    @apply tw-mx-0;
  }

  vex-secondary-toolbar {
    @apply tw-rounded-b tw-overflow-hidden;

    .secondary-toolbar {
      @apply tw-shadow-none tw-border-t-0 tw--mb-gutter;
    }
  }

  vex-footer {
    @apply tw-rounded-t tw-overflow-hidden;
  }

  .vex-page-layout-header {
    background: var(--background-base);
    color: var(--text-color);

    vex-breadcrumbs {
      .text-hint {
        color: var(--text-hint) !important;
      }
    }
  }

  &.vex-style-default {
    --sidenav-item-background-active: theme('backgroundColor.primary');
    --sidenav-item-color-active: theme('textColor.primary-contrast');
    --sidenav-item-dropdown-color-active: var(--sidenav-item-color);
    --sidenav-item-dropdown-color-hover: var(--text-color);
    --sidenav-item-icon-color-active: theme('textColor.primary-contrast');
  }
}
