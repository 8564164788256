//Bootstrap Overide variables 
/*$grid-gutter-width: 0px;*/

//Reusable variables
$red-bg-color:#aa032f;
$white: #fff;
$orange-bg-color:#ff4e00;
$gray-bg-color:#424242;
$color-orange: #ff4e00;
$color-dark-gray: #424242;
$color-light-gray: #DBD6D3;
$color-off-white: #F4F2EB;
$color-warm-green: #BAB79D;
$color-warm-gray: #A39990;
$color-red: #D32300;
$color-teal: #246C7B;

//Header Section Variables
$header-bg-color: $red-bg-color;
$header-font-color: $white;
$header-icon-color: $white;
$header-height: 62px;

//Sidebar Section Variables
$sidebar-header-icon-height: $header-height;
$sidebar-initial-width: 50px;
$sidebar-minimum-width: 200px;
$sidebar-bg-image: linear-gradient(90deg,#3c3c3c,#656565);

//Menu Item Variables
$menu-item-height: 50px;
$menu-item-border-bottom: 1px solid $white;
$menu-item-hover-color: rgba(51, 174, 184, 0.6);
$menu-item-selected-color: #33aeb8;
$menu-item-icon-width: 20px;
$menu-item-icon-cont-width: $menu-item-icon-width;
$menu-item-icon-color:$white;
$menu-item-font-color: $white;

