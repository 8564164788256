/* You can add global styles to this file, and also import other style files */

@import "assets/sass/custom-bootstrap";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "assets/sass/toastr";
@import "../node_modules/ngx-toastr/toastr.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
// Vex Core
@import "./@vex/styles/core";

* {
  --blink-scrollbar-width: 6px;
}

:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;
  // --color-accent: yellow;
  // --color-accent-contrast: black;
  // --color-warn: yellow;
  // --color-warn-contrast: black;
  --toolbar-background: #ff4e00;
}

.toastr-link {
  // color: var(--color-orange) !important;
  // color: #424242 !important;
  text-decoration: underline;
  // text-decoration-color: var(--color-orange);
  // font-weight: bold;
}

.toast-message a:hover {
  color: #fff !important;
  text-decoration: underline !important;
}

.toast-title, .toast-message {
  font-family: var(--font-vex-form) !important;
}

.form-page-att-label {
  font-weight: 600 !important;
  font: 16px var(--font-vex-form);
}

.cdk-global-scrollblock {
  overflow: auto !important;
}

.vex-input:hover,
.form-page-att-input:focus {
  border-bottom: 1px solid var(--color-orange);
}
.form-page-att-input {
  width: 100%;
  background: var(--color-off-white);
  border: none;
  border-bottom: 1px solid silver;
  height: 32px;
  line-height: 18px;
  font: 16px var(--font-vex-form);
}

.vex-input {
  background: var(--color-off-white);
  border: none;
  border-bottom: 1px solid silver;
}

.mat-datepicker-toggle {
  color: var(--color-orange);
}

.cdk-overlay-pane {
  z-index: 10000;
}

.preloader-background {
  background-attachment: fixed;
  background-image: url("./assets/images/bg1.png");
  background-size: cover;
}

.mydpicon:hover {
  color: #ff4e00;
}

.mydp {
  border: none !important;
  border-bottom: 1px solid silver !important;
}

.ag-theme-alpine .ag-header-cell-menu-button:hover {
  color: var(--color-orange) !important;
}

.json-key {
  // color: brown;
  color: #424242;
  font-weight: 500;
}
.json-value {
  // color: navy;
  color: var(--color-orange);
    font-weight: 600;
}
.json-string {
  color: olive;
  // color: var(--color-dark-gray);
  font-weight: 500;
  font-style: italic;
}

.last-transactions .mat-option-text:hover{
  background-color: var(--color-off-white);
  border-bottom: 1px solid var(--color-orange);
}

.last-transactions .mat-select-value {
  color: var(--color-light-gray);
}

:root .mat-option:hover, :root .mat-option.mat-active {
  color: var(--color-orange) !important;
}

:root .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: var(--color-off-white) !important;
}

:root .mat-option:hover, :root .mat-option.mat-active {
  background-color: var(--color-off-white) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(var(--color-orange), 1);
}
.box-shadow {
  box-shadow: 1px 1px 4px #000;
}

.ag-theme-balham .ag-header {
  background-color: #fff !important;
  border-bottom: none !important;
  font: 14px var(--font-vex-form) !important;
  color: #000 !important;
}

#summary-grid-container .ag-cell-label-container {
  width: auto !important;
}

#summary-grid-container .ag-theme-alpine .ag-header-icon {
  padding-left: 30px !important;
}

textarea:focus {
  border-color: transparent !important;
  box-shadow: 1px 1px 3px #000 !important;
}

.ag-theme-balham .ag-row-hover {
  //background-color: #ecf0f1 !important;
  //background-color: var(--color-off-white) !important;
}

.ag-theme-balham .ag-row {
  // background-color: var(--ag-background-color, white) !important;
  font: 14px var(--font-vex-form) !important;
  color: var(--ag-data-color, var(--ag-foreground-color, #000)) !important;
  border: 1px solid var(--ag-row-border-color, #d9dcde) !important;
}

.mat_btn_hov_act,
.mat-button-toggle-group:not(.mat-button-toggle-vertical) {
  box-shadow: 0px 2px 3px #000;
  font-weight: normal !important;
}

.btn-teal {
  background-color: var(--color-teal) !important;
  color: #fff !important;
}

.btn-clear-red {
  background-color: var(--color-red) !important;
  color: #fff;
}

.btn-teal:hover,
.mat_btn_hov_act:hover {
  color: var(--color-orange) !important;
}

.btn-teal:active,
.mat_btn_hov_act:active {
  box-shadow: 0 1px 2px var(--color-orange) !important;
}

.mat-button-toggle-label-content {
  line-height: 36px !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 28px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--color-orange) !important;
}

.test-harness-tab .mat-tab-header {
  border: none !important;
}

.mat-tooltip {
  font-size: 12px !important;
  background: #424242 !important;
}

.modal-header {
  border-bottom: 1px solid #ff4e00 !important;
}

.mat-expansion-panel-header:not([aria-disabled="true"]) {
  border-bottom: 1px solid var(--color-teal) !important;
}

.mat-expansion-panel-header-title {
  color: var(--color-dark-gray);
}

h4.modal-title {
  font-size: 19px !important;
  color: var(--color-dark-gray) !important;
  font-family: var(--font) !important;
}

pre {
  font: 16px var(--font-vex-form) !important;
  line-height: 24px !important;
}

.mat-expansion-panel-header {
  background: linear-gradient(
    90deg,
    rgba(255, 78, 0, 1) 0%,
    rgba(255, 255, 255, 1) 1%,
    rgba(255, 255, 255, 1) 99%,
    rgba(36, 108, 123, 1) 100%
  ) !important;
}

.raised-btn {
  box-shadow: 3px 3px 8px #000;
}

.raised-btn:active {
  box-shadow: 3px 3px 3px #000;
}

.component-heading-container {
  background-color: #fff;
}
.mat-button-toggle-appearance-standard {
  color: #fff;
  background: var(--color-teal);
  font: 14px var(--font);
}

.mat-button-toggle-button {
  font-weight: normal !important;
}

.mat-button-toggle-group-appearance-standard {
  border: none !important;
}

.text-icon-orange {
  color: #ff4e00;
}

.select_material {
  @import "node_modules/@ng-select/ng-select/themes/material.theme";
}

.select_default {
  @import "node_modules/@ng-select/ng-select/themes/default.theme";
}

.select_ant {
  @import "node_modules/@ng-select/ng-select/themes/ant.design.theme";
}
.filter_dropdown {
  border: 1px solid #246c78;
  border-radius: 5px 5px 5px 5px;
  height: 38px;
  font-family: var(--font-vex-form);
  font-size: 14px;
  float: left;
  position: relative;
  display: inline-block;
  background-color: #246c78;
  color: #fff;
  margin-left: 5px;
}

.modal-body {
  padding: 30px;
}
.modal-backdrop {
  display: none;
  z-index: 1040 !important;
}

.modal-content {
  margin: 2px auto;
  z-index: 1100 !important;
}

button:disabled,
input:disabled {
  cursor: not-allowed !important;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container {
  background-color: #e2e2e2;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

/*PUJA CSS*/
.gutter_space {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.header {
  background: #aa2537;
  height: 60px;
}

.header .logo {
  border-right: 1px solid #fff;
}

.header .logo > img {
  padding-bottom: 6px;
}

.header .burder_icon {
  height: 60px;
  line-height: 60px;
  margin-right: 5%;
}

.text_center {
  text-align: center;
}

.left_panel {
  background: #3b3d3e;
  min-height: 500px;
}

.right_panel {
  background: #e3e8e6;
  min-height: 500px;
}

.footer {
  color: #000;
}

.footer span {
  color: #0e9cbd;
}

.pdrgt15 {
  padding-right: 15px;
}

.mrgrgt0 {
  margin-right: 0;
}

.breadcrumb_panel {
  color: #000;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.container_part {
  background: #f7fcfa;
  padding-left: 15px;
}

.section_panel {
  height: 100%;
}

.section_panel h3 {
  font-size: 14px;
  margin: 15px 0;
  padding: 0;
}

.section_panel h4 {
  font-size: 14px;
  margin: 10px 0;
  padding: 0;
}

.section_panel .col-md-2.wdth_style_fix {
  max-width: 16.6%;
}

.bggray {
  background: #f2f2f2;
}

.error_msg {
  color: #f45249 !important;
  font-size: 12px !important;
  margin-right: 0 !important;
  float: left;
  padding-top: 3px;
}

/*--------------------- styles added by nilesh - 27Nov2018-------------------------*/

/* Fonts Encoded sans start here*/

@font-face {
  font-family: "EncodeSans-Regular";
  src: url("/assets/fonts/EncodeSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-Medium";
  src: url("/assets/fonts/EncodeSans-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-Light";
  src: url("/assets/fonts/EncodeSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-ExtraLight";
  src: url("/assets/fonts/EncodeSans-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-Thin";
  src: url("/assets/fonts/EncodeSans-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-Black";
  src: url("/assets/fonts/EncodeSans-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-SemiBold";
  src: url("/assets/fonts/EncodeSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-Bold";
  src: url("/assets/fonts/EncodeSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EncodeSans-ExtraBold";
  src: url("/assets/fonts/EncodeSans-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Fonts Encoded sans End here*/

body {
  font-family: "EncodeSans-Regular" !important;
}

h4.modal-title {
  font-size: 16px;
  color: #333;
  font-family: "EncodeSans-SemiBold";
}

.modal-header {
  border-radius: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #666;
}

.modal-header .close {
  opacity: 1;
  font-size: 29px;
}

.modal-header .close:hover {
  color: var(--color-orange);
}

.modal-content {
  border-radius: 10px !important;
  box-shadow: 0 0 20px var(--color-dark-gray);
  border: none;
}

.dropdown-item:hover,
.dropdown-item:active {
  color: var(--color-dark-gray) !important;
  border-bottom: 1px solid var(--color-orange) !important;
  background-color: var(--color-off-white) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #ff4e00 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #ff4e00 !important;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: #ff4e00 !important;
  caret-color: #ff4e00 !important;
}

.mat-form-field-underline {
  /*change color of underline*/
  background-color: #ff4e00 !important;
}

.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: #ff4e00 !important;
}

.add_rule_type .modal-body {
  padding: 30px 60px 40px;
}

.add_copy_rule .modal-body {
  padding: 30px 60px 40px;
}

.deployment_confirmation .modal-body {
  padding: 30px 30px 30px;
}

.add_copy_form .modal-body {
  padding: 30px 60px 40px;
}

.add_copy_form .modal-body input[type="text"] {
  width: 100%;
}

.add_copy_rule .modal-body input[type="text"] {
  width: 100%;
}

#grid_container .ag-checkbox {
  margin-top: 5px !important;
}

.modal-body label {
  margin-bottom: 5px !important;
  font-size: 15px;
  // font-family: "EncodeSans-Medium";
  font-family: var(--font-vex-form);
}

.modal-footer {
  border-top: 1px solid var(--color-teal);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--color-teal);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-orange);
}

.mrgbtm20 {
  margin-bottom: 20px !important;
}

.mrgbtm10 {
  margin-bottom: 10px !important;
}

.mrgrgt10 {
  margin-right: 10px !important;
}

.mrglt10 {
  margin-left: 10px !important;
}

.btn.primary_btn {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 12px 30px !important;
  border-radius: 5px !important;
  background-color: #246c78 !important;
  color: #fff !important;
  text-transform: uppercase;
  border: 0 !important;
  font-family: var(--font-vex-form);
  letter-spacing: 0.5px;
  font-size: 16px !important;
}

.btn.secondary_btn {
  box-shadow: none !important;
  padding: 10px 21px !important;
  border-radius: 5px !important;
  background-color: #fff;
  font-weight: bold;
  color: #009aa6;
  text-transform: uppercase;
  border: 1.5px solid #009aa6;
}

:focus {
  outline: none;
}

.error_msg {
  color: #f45249 !important;
  font-family: "EncodeSans-Regular";
  font-size: 12px !important;
  margin-right: 0 !important;
  float: left;
  padding-top: 3px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: transparent !important;
  cursor: not-allowed;
}

.ng-select.ng-select-disabled
  > .ng-select-container
  > .ng-value-container
  > .ng-placeholder,
.ng-select.ng-select-disabled
  > .ng-select-container
  > .ng-value-container
  > .ng-value {
  opacity: 0.75 !important;
  cursor: not-allowed;
}

.ng-select.ng-select-disabled
  > .ng-select-container
  > .ng-value-container
  > .ng-value
  > span {
  cursor: not-allowed;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 14px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0 !important;
}

.fast_admin .custom-select .ng-select-container {
  border-bottom: 1px solid #818284 !important;
  height: 30px;
}

.fast_admin
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding-left: 0 !important;
}

.fast_admin .ng-select .ng-select-container .ng-value-container {
  padding-left: 0 !important;
}

.fast_admin .ng-select .ng-arrow-wrapper {
  display: none;
}

.fast_admin .container-icon-right .form-control {
  padding-left: 0 !important;
}

.fast_admin .custom-inputs input {
  border-bottom: 1px solid #818284 !important;
  padding-left: 0;
}

.fast_admin .selectdiv:after {
  width: 14px !important;
  height: 10px !important;
  content: " " !important;
  background-image: url(/assets/images/drop_down_arrow.svg) !important;
  background: no-repeat;
  background-size: contain;
  padding: 15px 0px 0px 8px;
  position: absolute;
  top: 15px !important;
  right: 10px !important;
  z-index: 9 !important;
  pointer-events: none;
}

.selectdiv {
  position: relative;
  float: left;
  width: 100%;
}

.selectdiv label {
  width: 100%;
}

.fast_admin .ng-select .ng-clear-wrapper .ng-clear {
  display: none;
}

.fast_admin .custom-select .ng-select-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.fast_admin.ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  width: auto;
}

.fast_admin.ng-select .ng-select-container .ng-value-container .ng-input {
  width: 0 !important;
}

.fast_admin .ng-value-icon {
  display: none;
}

.fast_admin .ng-option.ng-option-marked {
  background: #99d7db;
  // font-family: "EncodeSans-Medium";
  font-family: var(--font-vex-form);
  font-size: 16px;
  font-weight: normal;
}

.queue-container .mat-expansion-panel-body {
  padding: 0px !important;
}

.ng-option.ng-option-marked {
  border-bottom: 1px solid var(--color-orange);
  background-color: var(--color-off-white);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 14px !important;
  font-weight: normal;
  padding: 5px;
}

.fast_admin .ng-option.ng-option-selected {
  color: #fff !important;
  // font-family: "EncodeSans-Medium";
  font-family: var(--font-vex-form);
  font-size: 16px;
  font-weight: normal;
}

.fast_admin
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label {
  color: #3a383d;
  font-family: "EncodeSans-SemiBold";
  font-size: 16px;
}

.fast_admin
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  color: #3a383d !important;
  font-family: "EncodeSans-SemiBold" !important;
  font-size: 16px;
}

.fast_admin
  .custom-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  // font-family: "EncodeSans-Medium";
  font-family: var(--font-vex-form);
  font-size: 14px !important;
  font-weight: normal;
  padding: 5px;
}

.ng-dropdown-panel .scroll-host {
  --blink-scrollbar-width: 5px;
}

.fast_admin .ng-option.ng-option-marked {
  background: var(--color-off-white);
  border-bottom: 1px solid var(--color-orange) !important;
  font-size: 16px;
  font-weight: normal;
}

.fast_admin .ng-dropdown-panel.ng-select-bottom {
  border: 1px solid #ccc !important;
  background: #fff;
}

.fast_admin .custom-select .ng-option-label {
  // font-family: "EncodeSans-Medium";
  font-family: var(--font-vex-form);
  font-size: 14px;
  font-weight: normal;
  color: #616365;
  text-transform: capitalize;
}

.fast_admin .ng-dropdown-panel.ng-select-bottom {
  margin-top: 0 !important;
}

.ng-select {
  padding: 7px 5px !important;
}

.fast_admin .custom-select {
  background: none;
  padding-left: 5px !important;
  height: 30px;
  color: var(--color-dark-gray);
  font-size: 14px !important;
  border: 0 !important;
}

.error_icon {
  background: url("/assets/images/error_icon.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: relative;
  left: -3px;
  float: left;
}

.svg-inline--fa.fa-edit {
  display: inline-block;
  cursor: pointer;
}

#form-list.svg-inline--fa.fa-copy {
  display: inline;
  cursor: pointer;
  width: 40%;
  height: 50%;
}

.ng-dropdown-panel .scroll-host {
  background: #fff;
}

.custom-select {
  height: auto !important;
}

.component-heading-text {
  border-left: 2px #ff4e00 solid;
  font-size: 15px;
  font-weight: 500;
}

.label_heading {
  float: left;
  margin-top: 12px;
  font-weight: bold;
  font-size: 14px;
  margin-right: 0px;
}

.gen-log-details {
  color: var(--color-warn-contrast) !important;
  font-family: var(--font-vex-form) !important;
  margin-left: 10px !important;
}

.gen-log-label {
  font-weight: 500 !important;
  font-family: var(--font-vex-form) !important;
}

.select_wrapinline {
  display: inline-block;
  width: 65%;
}

.btn.primary_btn.btnlign {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-right: 10px;
}

.clear_both {
  clear: both !important;
}

.wdth100p {
  width: 99.5% !important;
}

.edit_icon {
  background: url(/assets/images/edit_icon.png) no-repeat;
  height: 12px;
  width: 14px;
  cursor: pointer;
  display: inline-block;
}

.copy_icon {
  background: url(/assets/images/copy_icon.png) no-repeat;
  height: 12px;
  width: 10px;
  cursor: pointer;
  display: inline-block;
}

.cursor_pointer {
  cursor: pointer;
}

.btn_wrapper {
  float: right;
  margin-top: -8px;
}

.btn.secondary_btn.btnlignpd {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
  margin-right: 10px;
}

.row {
  width: 100% !important;
}

.component-content-view .heading-container {
  width: 100% !important;
  float: left;
}

.component-container {
  float: left;
  padding-bottom: 20px;
}

.float_none {
  float: none !important;
}

input,
button,
select,
optgroup,
textarea {
  border: 1px solid #818284;
}

.fa-plus {
  cursor: pointer;
}

.label_heading.float_none {
  float: none !important;
}

.mrglft15 {
  margin-left: 15px !important;
}

#import-queue-tabs .mat-expansion-panel-header,
#mDocMasterView .mat-expansion-panel-header {
  background: none !important;
}

.ag-theme-balham {
  box-shadow: 0 1px 3px #000 !important;
  margin-bottom: 10px;
}

.ag-theme-balham .ag-header-row {
  border-bottom: 1px solid var(--color-orange) !important;
}

.ag-theme-balham .ag-root-wrapper {
  border: 0 !important;
  border-color: snow;
}

.input_txt_bordr {
  border: 0 !important;
  border-bottom: 1px solid #818284 !important;
}

.select_wrapinline1 {
  display: inline-block;
  max-width: 65% !important;
}

.ng-value {
  padding-top: 4px;
}

// .component-content-view {
//   display: block !important;
// }


.ag-theme-balham .ag-menu {
  z-index: 1000000;
}

.mat-tab-nav-bar {
  border-bottom: 1px solid #dbd6d3 !important;
}

.mat-tab-label-active {
  background-color: #dbd6d3 !important;
  color: #000 !important;
}

.ag-theme-alpine .ag-header-row {
  height: 56px;
}

.ag-header-cell-label {
  justify-content: left !important;
  color: #000;
}

.ag-theme-balham .ag-header-icon {
  color: var(--color-orange);
}

#packageReportContainer .ag-selection-checkbox {
  margin-top: 1px !important;
}

#deploy-content-container .ag-theme-balham .ag-cell {
  padding-left: 3px;
  padding-right: 0px;
}

.ag-theme-alpine .ag-header-cell-label {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 12px;
  font-weight: 500;
  border-bottom-color: rgba(82, 63, 105, 0.06);
}
.ag-theme-alpine .ag-header-cell-text {
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  color: #000000;
  font: 16px var(--font);
}

.ag-theme-alpine .ag-cell-value {
  font: 15px var(--font-vex) !important;
}

.ag-theme-balham .ag-paging-panel > * {
  font-size: 13px;
  color: #000;
}

.ag-theme-alpine .ag-header {
  background-color: #ffffff;
  border-bottom: solid 1px;
  border-bottom-color: #ff4e00;
}

.ag-theme-alpine .ag-paging-panel > *,
.ag-theme-alpine .ag-paging-row-summary-panel-number {
  font: 14px var(--font);
}

.ag-theme-alpine .ag-cell-value {
  font: 14px "inter var";
  top: 8px;
}

.ghostPortal {
  &.ag-theme-balham .ag-header-cell-label {
    float: none !important;
    width: auto !important;
    justify-content: center;
  }

  &.ag-theme-balham .ag-header-cell-menu-button {
    position: absolute;
    float: none;
  }

  &.ag-theme-balham .ag-cell {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
  }

  &.ag-theme-balham .ag-header {
    background-color: #fff;
    color: #000;
    font-size: 14px;
  }
}

.diagnostics {
  &.ag-theme-balham .ag-header-cell-label {
    float: none !important;
    width: auto !important;
    justify-content: center;
  }

  &.ag-theme-balham .ag-header-cell-menu-button {
    position: absolute;
    float: none;
  }

  &.ag-theme-balham .ag-cell {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
  }

  &.ag-theme-balham .ag-header {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: bold;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $color-teal;
}

// MDO styles shared by multiple components
.mdo-standard-button {
  background-color: $color-teal;
  color: $color-off-white;
  border-radius: 5px !important;
}

.mdo-standard-button-dropdown {
  background-color: $color-teal;
  color: $color-off-white;
  border-radius: 5px;
  margin-top: -7px;
}

.mdo-standard-button-dropdown button {
  color: $color-off-white;
}

.mdo-soft-button {
  background-color: $color-off-white;
  color: $color-dark-gray;
  border-radius: 5px !important;
  padding: 5px;
}

.mdo-standard-info-block {
  background-color: $color-warm-gray;
  color: $color-off-white;
  border-radius: 5px;
}

.mdo-mat-panel-header {
  border-bottom: 1px solid $color-teal !important;
  border-radius: 5px 5px 0px 0px !important;
}

.mdo-panel-content-top-margin {
  margin-top: 20px;
}

.mdo-alert-success {
  background-color: $color-orange;
  border-color: $color-orange;
  color: $color-off-white;
  font-weight: 200 !important;
}

.mdo-alert-danger {
  background-color: $color-red;
  color: $color-off-white;
}

.mdo-alert-danger-link {
  color: $color-warm-green !important;
  font-weight: 700 !important;
}

.mdo-mat-option:hover {
  background-color: $color-off-white !important;
  color: $color-dark-gray;
}

.mdo-mat-option.mat-active {
  background-color: $color-off-white !important;
}

.mdo-mat-option.mat-selected {
  background-color: $color-off-white !important;
  color: $color-teal !important;
}

.mdo-snack-msg {
  background: $color-teal;
  color: #fff !important;
}

.mdo-snack-msg button {
  background-color: $color-teal;
  color: #fff !important;
  border: none;
}

.mdo-snack-msg-error {
  background: $color-red;
  color: #fff !important;
}

.mdo-snack-msg-error button {
  background: $color-red;
  color: #fff !important;
  border: none;
}

.mdo-standard-grid {
  --ag-data-color: #171515 !important;
  --ag-balham-active-color: #246c7b !important; /* Checkbox color */
  --ag-row-hover-color: #dbd6d3 !important;
}

.mdo-standard-grid .ag-header-cell-text {
  color: #424242 !important;
}

.mdo-standard-grid .ag-row-selected {
  background-color: #f4f2eb !important;
}

.mdo-standard-link {
  color: $color-teal;
}

.mdo-checkbox-set .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #246c7b !important;
}
